<template>
  <div class="service">
      <h5 class="title">privacy policy</h5>
      <h3 class="title_big">Privacy Policy</h3>
      <!-- <div class="text_msg">this version was updated: september 5, 2021 </div> -->
      <div class="text_msg">【Preface】complex-design (hereinafter referred to as "we", "us") has always solemnly committed to protecting the personal information and privacy of users (hereinafter collectively referred to as "Users" or "you") who use complex-design products and services (hereinafter collectively referred to as "the Service"). When you use the Service, we may collect and use your personal information. We hope to explain to you through the complex-design Privacy Policy (hereinafter referred to as "this Policy") that we have the corresponding processing rules for the collection and use of your personal information, so as to better protect your rights and interests.</div>
      <div class="text_msg" style="font-weight:900;">before using this service, please be sure to carefully read and thoroughly understand this policy, especially the terms marked by bold/bold underlines, you should focus on reading, and confirm full understanding and consent before starting to use. if you do not agree to the contents of this policy, it may cause the service to not function properly, or fail to achieve the service effect we intend to achieve, and you should immediately stop accessing/using the service. your use of or continued use of the service constitutes your consent to our collection, use, storage, sharing, transfer and disclosure of your personal information in accordance with this policy, including updated versions.</div>
      <div class="text_msg">after reading this policy, if you have any questions about this policy or matters related to this policy, you may contact us through the feedback channels listed in the "how to contact us" section of this policy and we will answer your questions as soon as possible.</div>
     
      <div class="text_msg" >This policy will help you understand the following:</div>
      <a class="text_a" @click='goTop("#first")' >I, how we collect and use personal information</a>
      <a class="text_a" @click="goTop('#second')">II, how we use COOKIES or similar technologies</a>
      <a class="text_a" @click="goTop('#third')">III, personal information</a>
      
      <a class="text_a" @click="goTop('#fourth')">IV, how we store and protect personal information</a>
      <a class="text_a" @click="goTop('#v')">V. How to manage your personal information</a>
      <a class="text_a" @click="goTop('#vi')">VI. Third-party services</a>
      <a class="text_a" @click="goTop('#vii')">VII. Protection of Minors</a>
      <a class="text_a" @click="goTop('#viii')">VIII. Amendments and Notices</a>
      <a class="text_a" @click="goTop('#ix')">IX. How to Contact Us</a>

     <h3 class="title_big" id='first'>1. how we collect and use personal information</h3>
      <div class="text_msg">We collect your personal information primarily so that you and other users can use the complex-design Services more easily and satisfactorily. complex-design' goal is to provide all Internet users with a safe, fun, and instructive online experience, and this information helps us achieve this goal.</div>
      <div class="text_msg">(1) we will collect and obtain your personal information through the following channels:</div>
      <div class="text_msg">1. information you provide. for example:</div>
      <div class="text_msg">(1) Information you provide to us when you register for an account for complex-design services or use complex-design services;</div>
      <div class="text_msg">(2) Shared information that you provide to third parties through the complex-design Service, and information stored when you use the complex-design Service.</div>
      <div class="text_msg" style="font-weight:900;">Please note that if you disclose your information in the public areas visible to other users in complex-design, or in your responses to information uploaded or posted by others, such information may be collected and used by others. When you become aware that someone else has improperly collected or used your information, you may contact us through the feedback channels listed in the "How to Contact Us" section of this Policy.</div>
      <div class="text_msg">2. Your information shared by third parties. This is the shared information provided to you by third parties when they use complex-design services.</div>
      <div class="text_msg">3. Information we obtain about you. When you use complex-design services, we collect, aggregate, and record information, such as log information, location information, and device information.</div>
      <div class="text_msg">(ii) we will collect and use the following types of personal information from you for the following purposes:</div>
      <div class="text_msg">1. help you complete registration and login</div>
      <div class="text_msg">In order for us to provide you with a continuous and stable service and to ensure the security of your use of complex-design services, we need you to provide basic registration or login information, including mobile phone number, email address, and create your account number, username and password.</div>
      <div class="text_msg">You may also fill in or supplement your other additional information (such as your nickname, profile picture, gender, place of origin, occupation, education, date of birth, hobbies) during registration, login or subsequent use, which will help us provide you with a personalized and better service experience, but if you do not provide this information, it will not affect your use of the basic functions of complex-design services.</div>
      <div class="text_msg">in addition, in accordance with the conditions agreed in this service agreement and the relevant laws and regulations of the country, we also provide the cancellation of the registered account, you can contact us through the feedback channels listed in the "how to contact us" section of this policy to request the cancellation of your registered account.</div>
      <div class="text_msg">2. realize identity authentication</div>
      <div class="text_msg">in accordance with relevant laws and regulations and regulatory requirements, or in order to meet the needs of user identity verification, system and service security and other specific service functions, we may require you to provide real identity information (such as name, id card, passport, driver's license, household registration book), biometric information (static or dynamic facial features, fingerprints) and other identity information to complete identity authentication. if you do not provide the above information, we will not be able to provide you with the relevant functions and services.</div>
      <div class="text_msg">for the purpose of identity verification, you agree and authorize us to provide, inquire and verify your aforementioned identity information to the relevant identity certification agencies (such as personal credit reporting agencies, government departments, etc.) on our own or by entrusting a third party.</div>
      <div class="text_msg">please note that your identity information, biometric information, etc. are personal sensitive information, please provide it carefully, if you refuse to provide it, you may not be able to obtain related services, but it will not affect the normal use of other functions and services.</div>
      <div class="text_msg">3. maintain the normal operation of basic functions</div>
      <div class="text_msg">n the process of your use of our services, in order to provide you with basic functions such as browsing and searching, and to identify abnormal account status, understand product suitability, and ensure the network and operational security of complex-design services, so as to maintain the normal operation of the above basic functions, we may directly or indirectly collect, store and associate information about your use of services and usage methods, including:</div>
      <div class="text_msg">(1) Log information: When you use our services, we may automatically collect your detailed use of our services as relevant web logs. For example: your login account, search query content, IP address, browser type, telecom operator, network environment, language used, date and time of access and the web browsing history you visited, Push open history, stay time, refresh record, publishing history, follow, subscribe, favorite and share.</div>
      <div class="text_msg">(2) Device information: We may receive and record information about the device you use. For example: device model, operating system and version, client version, device resolution, package name, device settings, process and software list, device identifier (MAC/IMEI/Android ID/IDFA/OpenUDID/GUID/SIM card IMSI information), software and hardware characteristic information, device location related information (such as IP address, GPS location, and WLAN access points, Bluetooth and base station sensor information that can provide relevant information). In order to collect the above basic personal device information, we will apply for access to your device information, which we collect to provide you with our basic services and basic functions, and if you refuse to provide the above permissions, it may result in your inability to use the complex-design Services.</div>
      <div class="text_msg">please note that the device information and log information alone are information that cannot identify a specific natural person. if we combine such non-personal information with other information to identify a specific natural person, or use it in combination with personal information, such non-personal information will be regarded as personal information during the period of combined use, and we will anonymize and de-identify such personal information unless authorized by you or otherwise provided by laws and regulations. this information will be treated and protected as your personal information during the period of combined use in accordance with this policy.</div>
      <div class="text_msg">4. provide you with interactive and publishing services</div>

      <div class="text_msg">4. provide you with interactive and publishing services</div>
      <div class="text_msg">please note that the information you publicly post may contain your personal information or personal sensitive information, please consider it carefully before posting. if the information you publish involves the personal information of others, you should obtain the consent of others before publishing.</div>
      <div class="text_msg">5. help you complete the order, pay and deliver goods or services to you</div>
      <div class="text_msg">(1) When you order specific goods and/or services in the complex-design service, we will generate an order for you to purchase the goods and/or services through the system. During the order placement process, you will need to provide at least the consignee's personally identifiable information, name, shipping address, postal code, consignee, contact number, and payment status information. If you refuse to provide such information, we will not be able to complete the relevant delivery service. If you order goods or services for someone else through the complex-design Service, you will need to provide the foregoing information of that actual subscriber. Before providing us with the foregoing information of the actual subscriber, you need to ensure that you have obtained their authorized consent;</div>
      <div class="text_msg">(2) In order to show you the transaction information of the account number and ensure the security of the transaction, we will collect the information generated by you in the process of using the complex-design service (such as the goods or services you have purchased, the specific order number, the order creation time, the transaction amount) to show you and facilitate your order management;</div>
      <div class="text_msg">(3) in order to complete the order payment, deliver goods or services, confirm the transaction status and provide you with after-sales and dispute resolution services, we will collect your account number, order, transaction, payment, logistics information related to the transaction progress through the transaction object, payment institution, logistics company, etc. selected by you based on the exchange, or share your transaction information with the above service providers.</div>
      <div class="text_msg">6. provide you with customer service or other user response functions</div>
      <div class="text_msg">when you contact our customer service or use other user response features (such as making an in-sale after-sales request, personal information protection complaint or suggestion, other customer complaints and needs), we may need you to provide the necessary personal information to match and verify your user identity in order to protect your account and system security. we may also save your contact details (other contact details you use when you contact us or that you have offered to us), your correspondence/call logs and content with us, other information necessary to relate to your needs in order to contact you or help you resolve your issue, or to record the handling of the relevant issue and the results thereof.</div>
      <div class="text_msg">7. your personal information we collect through indirect means:</div>
      <div class="text_msg">Based on the complex-design Services you choose to use, we may obtain information from affiliates and third-party partners that you authorize to share. For example, when you use an account on a third-party platform to log in to complex-design services, we will obtain relevant information under the third-party account (including: username, nickname, avatar, subject to your authorization) based on your authorization, and bind your third-party account to your complex-design service account after you agree to this policy, so that you can directly log in and use the complex-design service through the third-party account. We will collect and use your information in accordance with relevant laws and regulations, in accordance with the agreement with affiliates or third-party partners, and in the belief that the source of the information provided by them is legitimate.</div>
      <div class="text_msg">8. information display and push of goods or services provided to you</div>
      <div class="text_msg">Based on the information you provide to us, the information we may collect, and the information we collect about you indirectly (e.g., your browsing and search history, device information, location information, order information). Based on the combination of one or more of the above information, we may carry out recommendation algorithm modeling, programmatic advertising recommendation algorithm modeling, user behavior analysis and user portraits, which are used to extract your browsing, search preferences, behavior habits, location information related characteristics, in order to provide you with page display and recommend personalized content that is more suitable for your needs. We may also send you marketing communications, user surveys via email, SMS or other means based on feature tags to help us improve complex-design services and to provide or promote the following goods and services to us/third parties:</div>
      <div class="text_msg">our products and services, including but not limited to: instant messaging services, online media services, interactive entertainment services, social networking services, payment services, internet search services, location and mapping services, applications and services, data management software and services, online advertising services, internet finance and other social media, entertainment, online games, e-commerce, information and communication software and services; and</div>
      <div class="text_msg">third-party goods and services, including but not limited to: internet services, food and catering, sports, music, movies, television, live performances and other arts and entertainment, books, magazines and other publications, clothing and accessories, jewelry, cosmetics, personal health and hygiene, electronics, collectibles, household utensils, appliances, home décor and furnishings, pets, cars, hotels, transportation and travel, banking, insurance and financial services, points and rewards programs, and other goods and services that we believe may be relevant to you.</div>
      <div class="text_msg">if you wish to manage our personalized services, you may do so in accordance with the guidelines provided by the individual services you use.</div>
      <div class="text_msg">9. provide you with security</div>
      <div class="text_msg">In order to improve the security of your use of the services provided by us and our partners, protect the personal and property safety of you or other users or the public from infringement, better prevent security risks such as phishing websites, fraud, network vulnerabilities, computer viruses, network attacks, network intrusions, etc., and more accurately identify violations of laws and regulations or yeesSkins service related agreement rules, we may collect, use or integrate your account information, transaction information, device information, log information and our affiliates, The Partner obtains the information authorized by you or shared in accordance with the law to comprehensively determine your account and transaction risks, conduct identity verification, detect and prevent security incidents, and take necessary recording, auditing, analysis and disposal measures in accordance with the law.</div>
      <div class="text_msg">10. you understand and agree that some individual services may require you to enable specific access rights in your device to achieve the collection and use of the information involved in these permissions. for example:</div>
      <div class="text_msg">(1) after you turn on the storage permission, you allow us to access your storage space so that you can download and save content, or implement the corresponding services and other functions through caching;</div>
      <div class="text_msg">(2) After you open the device information permission, you allow us to obtain your device information as a unique identifier of your device, in order to provide you with pages that are more suitable for your needs to display and recommend personalized content, understand product adaptability, identify abnormal status, and ensure the network and operational security of complex-design services;</div>
      <div class="text_msg">(3) after you open the album and/or camera (camera) permissions, you can upload and take photos/pictures/videos, and realize the functions of publishing information and comments, live video broadcasting or communicating with customer service to provide proof;</div>
      <div class="text_msg">(4) After you open the address book permission, we can obtain your address book friend information in order to provide you with friend status inquiries, invite your friends to use complex-design services, share content/products with people you know, and other functions;</div>
      <div class="text_msg">(5) after you turn on the microphone and related voice permissions, you can use the voice function to interact with our products, others, or contact customer service;</div>
      <div class="text_msg">(6) after you open the location permission, we can obtain your location information in order to let you interact with friends nearby or push personalized content to you;</div>
      <div class="text_msg">(7) after you turn on our permission to read/write your calendar, we can get your schedule time in order to provide you with functions such as recording and reminder.</div>
      <div class="text_msg">when you need to turn off these functional permissions, most mobile devices will support this requirement, please refer to or contact the service provider or manufacturer of your mobile device. please note that when you open any permission, it means that you authorize us to collect and use relevant information to provide you with corresponding services, and once you turn off any permission, it means that you cancel the authorization, and we will no longer continue to collect and use relevant information based on the corresponding permissions, nor can we provide you with the services corresponding to the permission. however, your decision to turn off permissions will not affect the collection and use of information previously based on your authorization.</div>
      <div class="text_msg">11. exceptions to authorized consent</div>
      <div class="text_msg">in accordance with relevant laws and regulations, the collection of your information in the following circumstances does not require your authorization and consent:</div>
      <div class="text_msg">(1) directly related to national security or national defense security;</div>
      <div class="text_msg">(2) directly related to public safety, public health, or major public interests;</div>
      <div class="text_msg">(3) directly related to criminal investigation, prosecution, trial, and enforcement of judgments;</div>
      <div class="text_msg">(4) for the sake of safeguarding the life, property and other major legitimate rights and interests of you or other individuals, but it is difficult to obtain the consent of the person;</div>
      <div class="text_msg">(5) the personal information collected is disclosed to the public by yourself;</div>
      <div class="text_msg">(6) necessary for signing and performing the contract according to your requirements;</div>
      <div class="text_msg">(7) collecting personal information from legally and publicly disclosed information, such as lawful news reports, government information disclosure, and other channels;</div>
      <div class="text_msg">(8) Necessary to maintain the safe and stable operation of complex-design services, such as discovering and disposing of products or services;</div>
      <div class="text_msg">(9) necessary for carrying out lawful news reporting;</div>
      <div class="text_msg">(10) de-identifying the information contained in the results when it is necessary to carry out statistical or academic research in the public interest and providing the results of academic research or descriptions to the outside world;</div>
      <div class="text_msg">(11) other circumstances stipulated by laws and regulations.</div>

      <div class="text_msg">12. tips on sensitive personal information</div>
      <div class="text_msg">sensitive personal information refers to personal information that, once leaked, illegally provided or abused, may endanger the safety of persons and property, and easily lead to damage to personal reputation, physical and mental health or discriminatory treatment. the above information provided by you or collected by us may contain your personal sensitive information, such as identity document number, personal biometric information (including facial recognition features, voiceprints, etc.), bank account numbers, communication records and content, property information, credit information, whereabouts, accommodation information, health and physiological information, and transaction information. please exercise caution and be mindful of your sensitive personal information, and you agree that we may process your sensitive personal information for the purposes and manner described in this policy.</div>
      
      <h3 class="title_big" id='second'>2. HOW WE USE COOKIES OR SIMILAR TECHNOLOGIES</h3>
      <div class="text_msg">WE OR OUR THIRD-PARTY PARTNERS MAY OBTAIN AND USE YOUR INFORMATION THROUGH COOKIES OR SIMILAR TECHNOLOGIES AND STORE SUCH INFORMATION AS LOG INFORMATION.</div>
      <div class="text_msg">By using COOKIES, we provide users with a simple and personalized web experience. A cookie is a small amount of data that is sent from a web server to your browser and stored on your computer's hard drive. We use cookies to benefit its users. For example, to make the login process for the complex-design virtual community faster, you can choose to store your username in a COOKIE. This makes it easier and faster next time you want to log in to complex-design' service. Cookies help us determine the pages and content you connect to, the time you spend on specific complex-design services and the complex-design services you choose.</div>
      <div class="text_msg">Cookies enable us to serve you better and faster and to personalize your experience on complex-design services. However, you should be able to control whether and how cookies are accepted by your browser. Please consult the file that came with your browser for more information on this.</div>
      <div class="text_msg">WE AND THIRD-PARTY PARTNERS MAY COLLECT AND USE YOUR INFORMATION THROUGH COOKIES OR SIMILAR TECHNOLOGIES AND STORE SUCH INFORMATION.</div>
      <div class="text_msg">WE USE OUR OWN COOKIES OR SIMILAR TECHNOLOGIES AND MAY USE THEM FOR THE FOLLOWING PURPOSES:</div>
      <div class="text_msg">1. REMEMBER YOUR IDENTITY. FOR EXAMPLE: COOKIES OR SIMILAR TECHNOLOGIES HELP US TO IDENTIFY YOU AS OUR REGISTERED USER OR TO SAVE INFORMATION ABOUT YOU ABOUT YOU OR OTHERWISE ABOUT YOU;</div>
      <div class="text_msg">2. Analyze your use of our services. We may use cookies or similar technologies to understand what activities you do with the complex-design Services, or which services are most popular;</div>
      <div class="text_msg">3. ADVERTISING OPTIMIZATION. COOKIES OR SIMILAR TECHNOLOGIES HELP US TO USE YOUR INFORMATION TO PROVIDE YOU WITH ADVERTISEMENTS THAT ARE RELEVANT TO YOU RATHER THAN GENERAL ADVERTISING.</div>
      <div class="text_msg">While we use cookies or similar technologies for the purposes described above, we may provide non-personally identifiable information collected through cookies or similar technologies to advertisers and other partners for the purpose of analyzing how you and other users use the complex-design Services and for advertising purposes.</div>

      <div class="text_msg">The complex-design Services may have cookies or similar technologies placed by advertisers and other partners. These cookies and/or similar technologies may collect non-personally identifiable information about you for the purpose of analyzing how users use the Services, to send you advertisements that may be of interest to you, or to evaluate the effectiveness of the Advertising Services. The collection and use of such information by these third-party COOKIES or similar technologies is not subject to this Policy, but is governed by their own information protection statements, and we are not responsible for third-party cookies or similar technologies.</div>
      <div class="text_msg">YOU MAY REFUSE OR MANAGE COOKIES OR SIMILAR TECHNOLOGIES THROUGH YOUR BROWSER OR USER-SELECTED MECHANISMS. HOWEVER, PLEASE NOTE THAT IF YOU DISABLE COOKIES OR SIMILAR TECHNOLOGIES, WE MAY NOT BE ABLE TO PROVIDE YOU WITH THE BEST SERVICE EXPERIENCE, AND SOME SERVICES MAY NOT BE USED NORMALLY. AT THE SAME TIME, YOU WILL STILL RECEIVE ADS, BUT THESE ADS WILL BE LESS RELEVANT TO YOU.</div>
      
      <h3 class="title_big" id='third'>3. personal information that we may share, transfer or disclose</h3>
      <div class="text_msg">(1) sharing</div>
      <div class="text_msg">We take the protection of your personal information very seriously. We will not share your personal information with any third party other than complex-design without your consent except in the following circumstances:</div>
      <div class="text_msg">1. To provide you with our services. We may share your information with our partners and other third parties to enable the complex-design Services to enable you to use the services you request. For example: payment institutions that provide payment services, merchants on the complex-design platform, partners that provide data services (including online advertising monitoring, data statistics, data analysis), and third-party logistics companies;</div>
      <div class="text_msg">2. share with third parties such as partners who entrust us with promotion, so that such consignees understand the coverage and effectiveness of promotion. for example, we may tell the client how many people have seen their promotional information or purchased the client's products after seeing it, or provide them with non-personally identifiable statistical information to help them understand their audience or customers;</div>
      <div class="text_msg">3. Necessary sharing with related parties. In order to facilitate our provision of consistent services to you based on a unified account system and to facilitate your unified management, personalized recommendations, system and account security, your personal information may be shared as necessary between us and our affiliates. For example, your information collected when you use one complex-design Service may be used in another complex-design Service to provide you with specific content or to show you information that is relevant to you rather than generally available. ；</div>
      <div class="text_msg">4. achieve other purposes described in the "how we collect and use personal information" section of article 1 of this policy;</div>
      <div class="text_msg">5. to fulfill our obligations and exercise our rights in this policy or other agreements we have reached with you;</div>
      <div class="text_msg">6. Within the scope permitted by laws and regulations, in order to comply with the law, protect us and our affiliates or partners, you or other complex-design users or the public interest, property or safety from damage, such as to prevent fraud and other illegal activities and reduce credit risk, we may exchange information with other companies and organizations. However, this does not include information that is sold, rented, shared or otherwise disclosed for profit in violation of the commitments made in this Policy;</div>
      <div class="text_msg">7. at your legal needs or with your authorization;</div>
      <div class="text_msg">8. provide your information at the legal request of your guardian;</div>
      <div class="text_msg">9. provided according to the single service agreement signed with you (including the electronic agreement signed online and the corresponding platform rules) or other legal documents;</div>
      <div class="text_msg">10. provided based on academic research;</div>
      <div class="text_msg">11. provided based on the social public interest in complying with laws and regulations.</div>
      <div class="text_msg">we will only share your personal information for lawful, legitimate, necessary, specific and clear purposes. for companies, organizations and individuals with whom we share personal information, we will sign strict confidentiality agreements with them and require them to process the information in accordance with our instructions, this policy, and any other relevant confidentiality and security measures.</div>
      <div class="text_msg">(2) assignment</div>
      <div class="text_msg">1. as our business continues to develop, we may enter into mergers, acquisitions, asset transfers or similar transactions, and your information may be transferred as part of such transactions. we will require new companies and organizations holding your personal information to continue to be bound by this policy, otherwise, we will require the company and organization to seek your authorization and consent again.</div>
      <div class="text_msg">2. after obtaining your explicit consent, we will transfer your personal information to a third party.</div>
      <div class="text_msg">(3) disclosure</div>
      <div class="text_msg">we will only disclose your personal information in the following circumstances and with industry-standard security measures:</div>
      <div class="text_msg">1. disclose the information you specify according to your needs and in the way of disclosure that you have expressly agreed to;</div>
      <div class="text_msg">2. in the case that it is necessary to provide your information according to the requirements of laws and regulations, mandatory administrative law enforcement or judicial requirements, we may disclose your information according to the type of information required and the method of disclosure. subject to laws and regulations, when we receive the above request for disclosure of information, we will require the recipient to issue a corresponding legal document, such as a subpoena or investigation letter. we strongly believe that the information requested from us should be as transparent as possible to the extent permitted by law. all requests are carefully reviewed to ensure that they have a legitimate basis and are limited to data that law enforcement has a legal right to obtain for specific investigative purposes.</div>
      <div class="text_msg">(4) exceptions to prior authorization and consent obtained when sharing, transferring, or disclosing information</div>
      <div class="text_msg">in the following cases, sharing, transferring, and disclosing your information does not require your prior authorization and consent:</div>
      <div class="text_msg">1. directly related to national security and national defense security;</div>
      <div class="text_msg">2. directly related to public safety, public health, or major public interests;</div>
      <div class="text_msg">3. directly related to judicial or administrative law enforcement such as criminal investigation, prosecution, trial and enforcement of judgments;</div>
      <div class="text_msg">4. for the sake of safeguarding the life, property and other major legitimate rights and interests of you or other individuals, but it is difficult to obtain the consent of the person;</div>
      <div class="text_msg">4. for the sake of safeguarding the life, property and other major legitimate rights and interests of you or other individuals, but it is difficult to obtain the consent of the person;</div>
      <div class="text_msg">6. collect information from legally and publicly disclosed information, such as legal news reports, government information disclosure and other channels;</div>
      <div class="text_msg">7. related to our performance of obligations under laws and regulations.</div>
      <div class="text_msg">in accordance with the provisions of the law, if personal information that has been de-identified is shared, transferred, or disclosed, and the recipient of the data cannot be restored and re-identified as the subject of the information, it is not an act of sharing, transferring, or publicly disclosing personal information, and the storage and processing of such data will not require you to notify you separately and obtain your consent.</div>
      
      <h3 class="title_big" id='fourth'>4. how we store and protect personal information</h3>
      <div class="text_msg">(1) storage of personal information</div>

      <div class="text_msg">unless otherwise required by laws, regulations or regulatory authorities, we will only store your personal information for the shortest and shortest period of time necessary for the purposes described in this policy. if we terminate the service or operation, we will promptly stop the activities that continue to collect your personal information, and will comply with the relevant laws and regulations to notify you in advance, and delete or anonymize your personal information after the termination of the service or operation, unless otherwise provided by laws, regulations or regulatory authorities.</div>
      <div class="text_msg">unless otherwise required by laws, regulations or regulatory authorities, we will only store your personal information for the shortest and shortest period of time necessary for the purposes described in this policy. if we terminate the service or operation, we will promptly stop the activities that continue to collect your personal information, and will comply with the relevant laws and regulations to notify you in advance, and delete or anonymize your personal information after the termination of the service or operation, unless otherwise provided by laws, regulations or regulatory authorities.</div>
      <div class="text_msg">1. laws and regulations have clear provisions;</div>
      <div class="text_msg">2. obtain your authorization and consent;</div>
      <div class="text_msg">3. Your use of complex-design services involves cross-border borders, and complex-design needs to provide your personal information overseas.</div>
      <div class="text_msg">in view of the above situations, we will ensure that it is implemented in accordance with the provisions of national laws and regulations and relevant regulatory departments, and provide adequate protection for your personal information.</div>
      <div class="text_msg">(2) protection of personal information</div>
      <div class="text_msg">(2) protection of personal information</div>
      <div class="text_msg">1. technical measures for data security</div>
      <div class="text_msg">we will adopt security measures in line with industry standards, including the establishment of reasonable systems and specifications, security technologies to prevent your personal information from unauthorized access, use, modification, and avoid data damage or loss.</div>
      <div class="text_msg">Our Services employ a variety of encryption technologies, for example, in some complex-design Services, we will use encryption technology (such as SSL) to encrypt and save your personal information, and isolate it through isolation technology to protect your personal information.</div>
      <div class="text_msg">in the use of personal information, such as information display, information association calculation, we will use a variety of data masking technology to enhance the security of information in use.</div>
      <div class="text_msg">we also use strict data access control and multi-factor authentication technology to protect personal information and avoid illegal use of data.</div>
      <div class="text_msg">2. other security measures we take to protect personal information</div>
      <div class="text_msg">we have the industry's most advanced data security management system with data as the core and around the data life cycle, which improves the security of the entire system from multiple dimensions such as organization construction, system design, personnel management, and product technology. we manage and standardize the storage and use of personal information by establishing a data classification and grading system, data security management specifications, and data security development specifications.</div>
      <div class="text_msg">we have comprehensive security control over data through information contact confidentiality agreements, monitoring and auditing mechanisms.</div>
      <div class="text_msg">we also conduct security and privacy training courses to enhance employees' awareness of security and awareness of personal information protection policies and procedures.</div>
      <div class="text_msg">3. We only allow complex-design employees and partners who need to know this information to access your personal information, and set up strict access control and monitoring mechanisms for this purpose. We also require all persons who may have access to your personal information to fulfill the corresponding confidentiality obligations. Failure to meet these obligations may result in legal liability or suspension of the partnership with complex-design.</div>
      
      <div class="text_msg">4, the Internet is not an absolutely secure environment, and e-mail, instant messaging, social software or other service software and other communication methods with other users can not determine whether it is fully encrypted, we recommend that you use such tools to use complex passwords, and pay attention to protect your information security. When communicating with third parties or purchasing goods and services through the complex-design Service, you will inevitably disclose your information, such as contact details or postal address, to your counterparty or potential counterparty. Please protect your information properly and provide it to others only when necessary.</div>
      <div class="text_msg">5. the internet environment is not 100% secure, and we will do our best to ensure or guarantee the security of the personal information you send to us. if our physical, technical, or administrative protection facilities are damaged, resulting in unauthorized access, public disclosure, alteration, or destruction of personal information, resulting in damage to your legitimate rights and interests, we will bear the corresponding legal responsibility.</div>
      <div class="text_msg">6. handling of security incidents</div>
      <div class="text_msg">6. handling of security incidents</div>
      <div class="text_msg">after the unfortunate occurrence of an information security incident, we will promptly inform you in accordance with the requirements of laws and regulations: the basic situation and possible impact of the security incident, the disposal measures we have taken or will take, the suggestions that you can prevent and reduce risks independently, and the remedial measures for you. at the same time, we will promptly inform you of the relevant circumstances of the incident by mail, letter, telephone, push notification, etc., and when it is difficult to inform the information subject one by one, we will take a reasonable and effective way to issue an announcement. at the same time, we will also take the initiative to report the handling of information security incidents in accordance with the requirements of regulatory authorities.</div>
      <div class="text_msg">Please understand that due to technical limitations and the limitations of risk prevention, even if we have tried to strengthen security measures, we cannot always guarantee the 100% security of information. You need to understand that the systems and communication networks you use to access complex-design services may cause problems due to circumstances beyond our control.</div>
      <div class="text_msg">Please be sure vo keep your account number, password and other identity elements in good hands. When you use complex-design services, we will identify you by your account number, password and other identity elements. Once you disclose the foregoing information, you may suffer losses and may be unfavorable to you. If you find that the account number, password and/or other identity elements may or have been leaked, please contact us immediately so that we can take appropriate measures in a timely manner to avoid or reduce the relevant losses.</div>
     
     <h3 class="title_big" id='V'>5. how to manage your personal information</h3>
      <div class="text_msg">(a) access, update and delete</div>
      <div class="text_msg">We encourage you to update and amend your personal information to make it more accurate and effective. We will use appropriate technical means or provide contact channels for submitting applications to ensure, as far as possible, that you can access, update and correct your personal information or other information provided when using complex-design services. For information about you collected through cookies or similar technologies, we also explain the selection mechanisms provided to you in the section "How we use cookies or similar technologies" section of this policy.</div>
      <div class="text_msg">If you want to inquire, modify or delete some of your information, please log on to complex-design account center (reg.163.com) and the relevant function page of each individual service, we provide you with relevant operation guidelines and operation settings, you can operate by yourself, if you have doubts or difficulties in the operation process, you can choose to contact us through the "How to contact us" section of this policy Contact us through the feedback channels listed and we will answer you as soon as possible.</div>
      <div class="text_msg">except as otherwise provided by laws and regulations, when you correct or delete your personal information or apply for account cancellation, we may not immediately correct or delete the corresponding information from the backup system, but will correct or delete this information when the backup is updated.</div>
      <div class="text_msg">(2) disclosure and sharing</div>
      <div class="text_msg">Our many services allow you to publicly share information about you not only with your social networks, but also with all users who use the service, such as information you upload or post on the complex-design Service, your responses to information uploaded or posted by others, uploading or publishing your information by email or in public areas where unspecified users in the complex-design Service are visible, and including location data and log information related to that information. As long as you do not delete the information you disclose or share, the information may remain in the public domain, and even if you delete the shared information, the information may still be independently cached, copied or stored by other users or third parties not under our control, or stored in the public domain by other users or such third parties. If you disclose or share your information through the above channels, resulting in the disclosure of your information, we are not responsible. Therefore, we remind and ask you to carefully consider whether to disclose or share your information through the above channels.</div>
      <div class="text_msg">(3) account cancellation</div>
      <div class="text_msg">We provide you with a way to cancel your account. In accordance with the conditions of our terms of service and the relevant laws and regulations of the country, you can submit an account cancellation application in the complex-design Account Center in accordance with the corresponding requirements (please log in to the email account https://reg.163.com and click "Account Cancellation"; mobile account please log in to the https://aq.reg.163.com and click "Account Cancellation"), The cancellation of some individual service accounts may require you to follow the specific guidelines prompted on the service page. You may also choose to contact us through the feedback channels listed in the "How to Contact Us" section of this policy and we will answer you as soon as possible.</div>
      <div class="text_msg">After your account is cancelled, we will stop providing you with all or part of complex-design services, and delete your personal information or anonymize it according to your request, except as otherwise provided by laws and regulations.</div>
      <div class="text_msg">(4) change the scope of your authorization and consent</div>
      <div class="text_msg">You can always choose whether or not to disclose personal information to us. Some personal information is necessary to use complex-design services, but most other information is provided at your discretion. You may change the scope of your authorization for us to continue collecting information or withdraw your authorization by deleting information, turning off device functions, canceling your account, etc.</div>
      <div class="text_msg">when the authorization is withdrawn, we can no longer provide you with the services corresponding to the withdrawal of the authorization, nor will we process your corresponding information. however, your decision to withdraw your authorization will not affect the processing of information previously carried out on the basis of your authorization.</div>
      <div class="text_msg">(5) constraining the automatic decision-making of information systems</div>
      <div class="text_msg">in some business functions, we may only make decisions based on non-manual automatic decision-making mechanisms including information systems, algorithms, etc. if these decisions significantly affect your legitimate interests, you have the right to ask us for an explanation and we will provide appropriate remedies.</div>
      <div class="text_msg">(f) to respond to your above request</div>
      <div class="text_msg">for security purposes, you may be required to provide a written request or otherwise prove your identity. we may ask you to verify your identity before processing your request. for your reasonable request, we will not charge a fee in principle, but for multiple repetitions and requests that exceed reasonable limits, we will charge a certain cost fee as appropriate. we may reject requests that are unwarrantedly repetitive, require excessive technical means (e.g., need to develop new systems or fundamentally change existing practices), pose a risk to the legitimate rights and interests of others, or are very impractical (e.g., involving information stored on backup tapes).</div>
      <div class="text_msg">we will not be able to respond to your request in the following situations:</div>
      <div class="text_msg">1. related to our performance of obligations under laws and regulations;</div>
      <div class="text_msg">2. directly related to national security and national defense security;</div>
      <div class="text_msg">3. directly related to public safety, public health, or major public interests;</div>
      <div class="text_msg">4. directly related to criminal investigation, prosecution, trial and enforcement of judgments;</div>
      <div class="text_msg">5. we have sufficient evidence that you have subjective malice or abuse of rights;</div>
      <div class="text_msg">6. for the purpose of safeguarding the life, property and other major legitimate rights and interests of you or other individuals, but it is difficult to obtain the consent of the person;</div>
      <div class="text_msg">7. responding to your request will cause serious damage to the legitimate rights and interests of you or other individuals or organizations;</div>
      <div class="text_msg">8. involving trade secrets.</div>
      
      
      <h3 class="title_big" id='vi'>6. third-party services</h3>
      <div class="text_msg">The complex-design Service may access or link to social media or other services (including websites or other forms of services) provided by third parties. include:</div>
      <div class="text_msg">1. You can use the "Share" button to share some complex-design service content to third-party services, or you can share third-party service content to complex-design services. These features may collect information about you (including your log information) and may place cookies on your device to function properly;</div>
      <div class="text_msg">2. we provide you with links through advertisements or other means of our services so that you can link to the services or websites of third parties;</div>
      <div class="text_msg">3. Other situations of access to third-party services. For example, for the purposes stated in this Policy, we may access SDKs or other similar applications provided by third-party service providers (e.g., in the form of embed codes, plug-ins, etc.) in order to provide you with better customer service and user experience. At present, the third-party service providers we access mainly include the following types:</div>
      <div class="text_msg">(1) for advertising-related services, including advertising display, advertising data monitoring/statistics, etc.;</div>
      <div class="text_msg">(2) Used for message push function, including mobile phone manufacturer Push push, specific event reminder, personalized content recommendation, etc.;</div>
      <div class="text_msg">(3) for payment related services, including order payment, transaction behavior verification, revenue settlement, payment information summary statistics, etc.;</div>
      <div class="text_msg">(4) to obtain device location permissions, collect device information and log information, etc. with your consent;</div>
      <div class="text_msg">(5) for third-party authorized services, including third-party account login, sharing relevant content to third-party products, etc.;</div>
      <div class="text_msg">(6) USED TO SUPPORT PRODUCT FUNCTION MODULES, INCLUDING ONLINE LIVE BROADCAST, VIDEO PLAYBACK, VOICE RECOGNITION, AR, INTELLIGENT CUSTOMER SERVICE, BULLET SCREEN PLAYBACK, CONTENT STORAGE, ETC.;</div>
      <div class="text_msg">(7) used to optimize product performance, including improving hardware network distribution capacity, reducing server costs, functional hot repair, etc.;</div>
      <div class="text_msg">(8) used for account security, product reinforcement related services, including network monitoring, domain name resolution, anti-hijacking, anti-spam and anti-cheating, encryption and decryption services, etc.</div>
      <div class="text_msg">Some of the third-party SDKs or similar applications to which we access may collect your personal information, and if you use such services provided by third parties in our Services, you agree that they will collect and process your information directly. We will assess the legality, legitimacy and necessity of the collection of personal information by such third-party services, require such third parties to take protective measures for your personal information, and strictly comply with relevant laws, regulations and regulatory requirements. You can click on the "Third-party SDK Directory" to understand the basic situation of the third-party SDK that we mainly access, the situation of each single service access to the third-party SDK may be different, if you want to know the details of the specific single service access to the third-party SDK, please go to the corresponding service page and check the specific terms and related pages of each of our individual services. You may also choose to contact us through the feedback channels listed in the "How to Contact Us" section of this Policy and we will answer you as soon as possible.</div>
      <div class="text_msg">the above third-party social media or other services are operated by the relevant third party. your use of such third party's social media or other services (including any information you provide to such third parties) is subject to the third party's own terms of service and information protection statement (not this policy), and you need to read their terms carefully. this policy applies only to the personal information we collect and does not apply to any services provided by third parties or to the rules of use of information by third parties. if you find that these third-party social media or other services are at risk, we recommend that you terminate the relevant operations to protect your legitimate rights and interests and contact us in a timely manner.</div>
      
      <h3 class="title_big" id='vii'>7. protection of minors</h3>
      <div class="text_msg">we recommend that any minor participate in online activities with the prior consent of a parent or other guardian ("guardian"). we will protect the relevant information of minors in accordance with the relevant laws and regulations of the state.</div>
      <div class="text_msg">Guardians are encouraged to instruct minors to use the complex-design Services. If you are a minor under the age of 14, please notify your guardian to jointly read and accept our "complex-design Children's Personal Information Protection Rules and Guardian Notice" and seek their consent and guidance before you use complex-design services and submit personal information.</div>
      
      <h3 class="title_big" id="viii">8. amendments and notices</h3>
      <div class="text_msg">In order to provide you with better services, we may modify the terms of this policy in a timely manner according to the update of complex-design services and the relevant requirements of laws and regulations, and such modifications form part of this policy. We will post any changes to this policy on this page. We may also provide more significant notice (including site announcements, push notifications, pop-up alerts, or otherwise) for material changes that include, but are not limited to, the material changes referred to in this Policy include:</div>
      <div class="text_msg">1. our service model has undergone major changes. such as the purpose of processing personal information, the type of personal information processed, the use of personal information, etc.;</div>
      <div class="text_msg">2. we have undergone significant changes in our ownership structure. such as business adjustments, bankruptcy mergers and acquisitions caused by changes in ownership, etc.;</div>
      <div class="text_msg">3. there have been major changes in the main objects of personal information sharing, transfer or public disclosure;</div>
      <div class="text_msg">4. your right to participate in the processing of personal information and the way in which it is exercised have undergone significant changes;</div>
      <div class="text_msg">5. there have been major changes in the departments responsible for handling personal information security, contact information and complaint channels;</div>
      <div class="text_msg">6. other important or situations that may seriously affect your personal rights and interests occur.</div>
      <div class="text_msg">If you do not agree to such changes, you may choose to discontinue your use of the complex-design Services, and if you continue to use the complex-design Services, you will fully read, understand and agree to be bound by the revised Policy.</div>
      <div class="text_msg">Any modifications we make will put your satisfaction first. We encourage you to review our Privacy Policy each time you use complex-design services.</div>
      <div class="text_msg">we may give you notices in connection with a service when necessary (for example, when we suspend a single service, change, or terminate the provision of a single service due to system maintenance).</div>
      <div class="text_msg">if you do not wish to continue to receive messages from us, you may ask us to stop pushing, for example, by asking us to stop sending promotional text messages in accordance with the sms unsubscribe guidelines, or by setting up a mobile device to no longer receive messages from us, except where we send messages in accordance with the law or the service agreement of the individual service.</div>
     
     <h3 class="title_big" id='ix'>9. amendments and noticeshow to contact us</h3>
      <div class="text_msg">We have established a dedicated personal information protection department that will protect your personal information in accordance with this policy. If you have complaints and reports about network information security, or if you have any questions, comments or suggestions about this policy, complex-design' privacy measures, and your information, please contact complex-design' personal information protection staff and send an email</div>
      <div class="text_msg">.267438914232@qq.com</div>
      
      <div class="text_msg"></div>in general, we will respond within fifteen working days of receiving your questions, comments or suggestions and verifying your user identity. if you are not satisfied with our response, you can also complain to the consumer protection department or file a lawsuit with a court of competent jurisdiction.
      <div class="text_msg" style='margin:50px 0;'>annex i:</div>
      
      <h3 class="title_big">complex-design Children's Personal Information Protection Rules and Guardian Notice</h3>
      <div class="text_msg">complex-design and its affiliates (collectively, "complex-design" or "we", "us") understand the importance of the security of children's personal information and privacy (meaning minors under the age of 14, the same below). We would like to use the complex-design Children's Personal Information Protection Rules and Guardian's Notice (hereinafter referred to as "This Policy") to explain our handling rules for the collection and use of children's personal information and other related matters. Before using our products and services (hereinafter collectively referred to as the "complex-design Services"), children, parents or other guardians of children (hereinafter collectively referred to as "Guardians") must carefully read and thoroughly understand this Policy, in particular, the terms marked in bold/underlined should be read carefully, and only after confirming full understanding and agreement to all terms and conditions should be started.</div>
      <div class="text_msg">guardian special instructions:</div>
      <div class="text_msg">If we learn that your child is under the age of fourteen, we will take special measures in accordance with this Policy to protect the personal information we obtain about your child. Please help us protect your child's personal information and privacy, require them to jointly read and accept this policy under your supervision, and should use complex-design services and submit personal information under your consent and guidance. If you do not agree to the contents of this policy, it may cause the complex-design Service to not function properly, or it may not be able to achieve the service effect we intend to achieve, and you should ask your child to immediately stop accessing/using the complex-design Service. By clicking agree to this Policy online, or by your child's use/continued use of complex-design services and by submitting personal information, you consent to our collection, use, storage, sharing, transfer and disclosure of your child's personal information in accordance with this policy (including updated versions).</div>
      <div class="text_msg">special instructions for children:</div>
      <div class="text_msg">We recommend that any child participate in online activities with the prior consent of a guardian. If you are a child, please notify your guardian to read this policy together and seek the consent and guidance of your guardian before you use complex-design services and submit personal information. By clicking on this Policy online, or by using/continuing to use the complex-design Services or submitting personal information, you have obtained permission from your guardian, who consents to our collection, use, storage, sharing, transfer and disclosure of your personal information in accordance with this Policy (including updated versions).</div>
      <div class="text_msg">This policy applies to activities such as collecting, using, storing, sharing, transferring and disclosing children's personal information through the Internet within the territory of the People's Republic of China. When a child uses any complex-design Service, the Guardian agrees to accept the protection of this Policy and the specific children's personal information protection rules and conditions (if any, hereinafter referred to as "Specific Terms") issued by us in the individual Service, in which case the specific terms shall be effective against both the Guardian and the child in this policy. In the event of any inconsistency between a particular term and a provision of this Policy, the specific term shall prevail to the extent that the specific terms are bound. If a single service provided by us is not applicable to this Policy, the application of this Policy will be expressly excluded in an appropriate manner in that service. In terms of children's personal information protection rules, this policy is inconsistent with the text of the complex-design Privacy Policy, and this policy applies in priority, if not mentioned in this policy, the complex-design Privacy Policy Subject to that.</div>
      <div class="text_msg">after reading this policy, if you have any questions about this policy or matters related to this policy, you can contact us through the feedback channels listed in the "how to contact us" section of this policy and we will answer them as soon as possible.</div>
      <div class="text_msg">this policy will help you understand the following:</div>
      
      <a @click="goTop('#minFirst')" class="text_a">first, how we collect and use children's personal information</a>
      <a @click="goTop('#minSecond')" class="text_a">second, we may share, transfer or disclose children's personal information</a>
      <a @click="goTop('#minThird')" class="text_a">third, how we store and protect children's personal information</a>
      <a @click="goTop('#minIv')" class="text_a">fourth, how to manage children's personal information</a>
      <a @click="goTop('#minV')" class="text_a">v. third-party services</a>
      <a @click="goTop('#minVi')" class="text_a">vi. revision of this policy</a>
      <a @click="goTop('#minVii')" class="text_a">vii. how to contact us</a>
    
      <h3 class="title_big" id='#minFirst'>1. how we collect and use children's personal information</h3>
      <div class="text_msg">(1) we will strictly perform the obligations and responsibilities for the protection of children's personal information as provided by laws and regulations, follow the principles of legitimate necessity, informed consent, clear purpose, safety guarantee, and lawful use, and collect and use children's personal information after obtaining the consent of the guardian:</div>
      <div class="text_msg">1. In some individual services, we may require users to fill in personal birthday information or identify whether the user is a child according to the identity information filled in by the user, and in accordance with the provisions of laws and regulations, only allow children to continue to use the relevant complex-design services after obtaining the consent of the guardian.</div>
      <div class="text_msg">2. when we identify a user as a child, we may collect the contact information of the guardian (such as mobile phone number, email address) and contact the guardian to verify his or her guardianship relationship with the child. in order to better protect the rights and interests of children, we may also collect more information from guardians (such as names, identity cards, household registration books or other proof of guardianship) in order to further verify the guardianship relationship between the guardian and the child.</div>
      <div class="text_msg">3. In the process of children using complex-design services, we may also collect and use other children's personal information, please refer to the "How we collect and use personal information" section of the "complex-design Privacy Policy" for details. If we need to collect and use children's personal information beyond the scope described above, we will again obtain the consent of the guardian.</div>
      <div class="text_msg">(2) exceptions to the acquisition of authorized consent</div>
      <div class="text_msg">in accordance with relevant laws and regulations, the collection of children's information in the following circumstances does not require the authorization and consent of the child and/or guardian:</div>
      <div class="text_msg">(1) directly related to national security, national defense security, public safety, public health, or major public interests;</div>
      <div class="text_msg">(2) directly related to criminal investigation, prosecution, trial, and enforcement of judgments;</div>
      <div class="text_msg">(3) for the purpose of safeguarding the major legitimate rights and interests of the information subject or other individuals, such as life and property, but it is difficult to obtain the consent of the guardian himself;</div>
      <div class="text_msg">(4) the information collected is disclosed to the public by the child or guardian on his or her own;</div>
      <div class="text_msg">(5) collecting information from lawfully and publicly disclosed information, such as lawful news reports, open government information, and other such channels;</div>
      <div class="text_msg">(6) it is necessary to conclude a contract according to the requirements of the guardian;</div>
      <div class="text_msg">(7) Necessary for maintaining the safe and stable operation of complex-design services, such as discovering and disposing of the failure of complex-design services;</div>
      <div class="text_msg">(8) automatically retaining and processing information through a computer information system and failing to identify that the information retained and processed is a child's personal information;</div>
      <div class="text_msg">(9) when it is necessary to carry out statistical or academic research in the public interest, and when the results of academic research or descriptions are provided to the outside world, the information contained in the results is de-identified;</div>
      <div class="text_msg">(10) other circumstances stipulated by laws and regulations.</div>
      <div class="text_msg">(3) tips on sensitive personal information about children</div>
      <div class="text_msg">child information provided by children or guardians or collected by us may contain sensitive personal information of children, such as identity card numbers, personal biometric information (including facial recognition features, voiceprints, etc.), bank account numbers, communication records and contents, property information, credit information, whereabouts, accommodation information, health and physiological information, and transaction information. please be cautious and pay attention to the child's personal sensitive information, the guardian agrees that the child's personal sensitive information we can process in accordance with the purposes and methods described in this policy.</div>
     
      <h3 class="title_big" id='minSecond'>2. we may share, transfer or disclose children's personal information</h3>
      <div class="text_msg"> sharing</div>
      <div class="text_msg">We will not share children's personal information with any third party other than complex-design without the consent of the guardian except in the following cases:</div>
      <div class="text_msg">1. Provide our services to children. We may share children's information with partners and other third parties to achieve the functions of complex-design services and allow children to normally use the services they need, such as: payment institutions that provide payment services, merchants on the complex-design platform, partners who provide data services (including online advertising monitoring, data statistics, data analysis), third-party logistics companies and other service providers;</div>
      <div class="text_msg">2. necessary sharing with related parties. in order to facilitate our provision of consistent services to children based on a unified account system and to facilitate their unified management, personalized recommendations, system and account security, etc., children's personal information may be shared as necessary between us and our affiliates;</div>
      <div class="text_msg">3. achieve the purposes described in the "how we collect and use children's personal information" section of article 1 of this policy;</div>
      <div class="text_msg">4. fulfill our obligations and exercise our rights in this policy or other agreements we have reached with children and guardians;</div>
      <div class="text_msg">5. share with third parties such as partners who entrust us with promotion, so that such entrusting parties understand the coverage and effectiveness of promotion. for example, we may tell the principal how many people have seen their promotional information or purchased the client's goods after seeing it, or provide them with statistical information that does not personally identify children to help them understand their audience or customers;</div>
      <div class="text_msg">6. To the extent permitted by laws and regulations, in order to comply with the law, protect us and our affiliates or partners, children, guardians or other complex-design users or the public interest, property or safety from damage, such as to prevent illegal activities such as fraud and reduce credit risk, we may exchange information with other companies and organizations. However, this does not include information that is sold, rented, shared or otherwise disclosed for profit in violation of the commitments made in this Policy;</div>
      <div class="text_msg">7. at the child's legal needs or with the authorization of the guardian;</div>
      <div class="text_msg">8. at the legal request of the guardian;</div>
      <div class="text_msg">9. provided in accordance with a single service agreement (including electronic agreements signed online and corresponding platform rules) or other legal documents;</div>
      <div class="text_msg">10. provided based on the social public interest in compliance with laws and regulations.</div>
      <div class="text_msg">we will only share children's personal information for lawful, legitimate, necessary, specific, and clear purposes. we conduct security assessments for the companies, organizations and individuals with whom we share personal information and sign strict confidentiality agreements with them to require them to process the information in accordance with our instructions, this policy, and any other relevant confidentiality and security measures.</div>
      <div class="text_msg">assignment</div>
      <div class="text_msg">1. as our business continues to develop, we may enter into mergers, acquisitions, asset transfers or similar transactions, and children's information may be transferred as part of such transactions. we will conduct a security assessment and require new companies and organizations holding children's personal information to continue to be bound by this policy, otherwise, we will require the company and organization to seek authorization and consent from their guardians again.</div>
      <div class="text_msg">2. after obtaining the consent of the guardian, we will transfer the child's personal information to other parties.</div>
      <div class="text_msg"> disclosure</div>
      <div class="text_msg">we will only disclose children's personal information if:</div>
      <div class="text_msg">1. disclose the information designated by the child or guardian according to the needs of the child or guardian in the form of disclosure consent of the guardian;</div>
      <div class="text_msg">2. in the case that children's information must be provided according to the requirements of laws and regulations, mandatory administrative law enforcement or judicial requirements, we may disclose children's information according to the type of information requested and the method of disclosure. subject to laws and regulations, when we receive the above request for disclosure of information, we will require the recipient to issue a corresponding legal document, such as a subpoena or investigation letter. we strongly believe that the information requested from us should be as transparent as possible to the extent permitted by law. all requests are carefully reviewed to ensure that they have a legitimate basis and are limited to data that law enforcement has a legal right to obtain for specific investigative purposes.</div>
      <div class="text_msg">(4) exceptions to prior authorization and consent obtained when sharing, transferring, or disclosing information</div>
      <div class="text_msg">in the following cases, sharing, transferring, or disclosing a child's information does not require the prior authorization and consent of the guardian:</div>
      <div class="text_msg">1. directly related to national security and national defense security;</div>
      <div class="text_msg">2. directly related to public safety, public health, or major public interests;</div>
      <div class="text_msg">3. directly related to judicial or administrative law enforcement such as criminal investigation, prosecution, trial and enforcement of judgments;</div>
      <div class="text_msg">4. for the purpose of safeguarding the life, property and other major legitimate rights and interests of children or other individuals, but it is difficult to obtain the consent of the guardian;</div>
      <div class="text_msg">5. information disclosed by children or guardians to the public on their own;</div>
      <div class="text_msg">6. collect information from legally and publicly disclosed information, such as legal news reports, government information disclosure and other channels;</div>
      <div class="text_msg">7. related to our performance of obligations under laws and regulations.</div>
      <div class="text_msg">in accordance with the provisions of law, where personal information that has been de-identified is shared, transferred, or disclosed, and the recipient of the data cannot be restored and re-identified as the subject of the information, it is not an act of sharing, transferring, or publicly disclosing personal information, and the preservation and processing of such data will not require separate notice to the guardian and the consent of the guardian.</div>
     
      <h3 class="title_big" id='minThird'>3. how we store and protect children's personal information</h3>
      <div class="text_msg">(1) unless otherwise provided by laws, regulations or regulatory authorities, we will only store children's personal information for the purposes described in this policy and for the shortest period of time. if we terminate the service or operation, we will promptly stop the activities of continuing to collect children's personal information, and will comply with the relevant laws and regulations required to notify the guardian in advance, and delete or anonymize the child's personal information after the termination of the service or operation, unless otherwise provided by laws, regulations or regulatory authorities.</div>
      <div class="text_msg">(2) we attach great importance to children's privacy and safety, set up a special security team, and take all reasonable and feasible measures to protect children's personal information:</div>
      <div class="text_msg">we will use technical measures such as encryption to store children's personal information to ensure information security. at the same time, we strictly set the information access rights for the staff on the principle of minimum authorization and control the scope of knowledge of children's personal information; where staff access children's personal information, they must be approved by the person in charge of children's personal information protection or their authorized management personnel, record the access situation, and take technical measures to avoid illegal copying and downloading of children's personal information. if we find that children's personal information has occurred or may be leaked, damaged or lost, we will immediately activate the emergency plan, take remedial measures, and inform the affected guardians and children of the relevant circumstances of the incident by email, letter, telephone, push notification, announcement, etc.</div>
      <div class="text_msg">If you would like to learn more, please review the "How We Store and Protect Personal Information" section of the complex-design Privacy Policy to learn more about how we store and protect children's personal information.</div>
      <div class="text_msg">Due to the limitations of technology and the limitations of risk prevention, even if we have tried to strengthen security measures, we cannot always guarantee the 100% security of information. Guardians need to be aware that the systems and communication networks children use to access complex-design services may cause problems due to circumstances beyond our control.</div>
      <div class="text_msg">Please keep the complex-design service account number, password and other identity elements in good care of your guardians and children. When a child uses complex-design services, we identify them by account number, password, and other identification elements. Once guardians and children disclose the foregoing information, they may suffer losses and may adversely affect themselves. If guardians and children discover that the account number, password and/or other identity elements may or have been compromised, please contact us immediately so that we can take appropriate measures in a timely manner to avoid or reduce the relevant losses.</div>
      
      <h3 class="title_big" id='minIv'>4. how to manage children's personal information</h3>
      <div class="text_msg">During the use of complex-design services by children, we will take appropriate action settings, guidelines or provisions in the "How to Contact Us" section of this policy in our individual services The contact channels listed to ensure, as far as possible, guardians and children can access, correct, delete children's personal information, and cancel children's registered accounts. When accessing, correcting, deleting, or requesting account cancellation, we may require guardians and/or children to authenticate their information to keep the information secure. Except as otherwise provided by laws and regulations, when guardians and children correct, delete children's personal information or apply for account cancellation, we may not immediately correct or delete the corresponding information from the backup system, but will correct or delete this information when the backup is updated.</div>
      <div class="text_msg">(1) access children's personal information</div>
      <div class="text_msg">Guardians and children can view the personal information of children provided or generated in the use of complex-design services, such as personal information, partial usage records, and posted content.</div>
      <div class="text_msg">(2) correction of children's personal information</div>
      <div class="text_msg">we encourage guardians and children to update and amend children's personal information to make it more accurate and effective. guardians and children who find errors in the personal information we collect, use and process about children may contact us to correct them. we take prompt action to correct identity verification and verification issues.</div>
      <div class="text_msg">(3) delete children's personal information</div>
      <div class="text_msg">Depending on the circumstances in which the child chooses the complex-design service, guardians and children may delete some of the children's personal information by themselves while using the complex-design service. Guardians and children can directly request deletion of children's personal information to us in the following cases, and we will take prompt measures to delete them after completing the identity verification and verification issues, including:</div>
      <div class="text_msg">1. we collect, store, use, transfer or disclose children's personal information in violation of laws and regulations or agree with guardians and children;</div>
      <div class="text_msg">2. collecting, storing, using, transferring, or disclosing children's personal information beyond the scope of the purpose or within the necessary period;</div>
      <div class="text_msg">3. the guardian withdraws his consent;</div>
      <div class="text_msg">4. The guardian or child terminates the use of complex-design services by means of cancellation, etc.</div>
      <div class="text_msg">Please note, however, that guardians and children who delete or request that we delete certain children's personal information on their own initiative may result in the inability to continue to use all or part of the complex-design Services.</div>
      <div class="text_msg">(4) cancel the account</div>
      <div class="text_msg">In accordance with the service agreement conditions of complex-design single service and relevant laws and regulations, we also provide cancellation of registered accounts, and guardians and children can operate in accordance with the specific settings and guidelines of the single service or contact us to deal with it.</div>
      <div class="text_msg">(5) change the scope of authorization and consent</div>
      <div class="text_msg">Guardians and children always have the option of disclosing information. Some information is necessary to use the complex-design Service, but most other information is provided at your own discretion. Guardians and children may change the scope of their authorization for us to continue collecting information or withdraw their authorization by deleting the information, turning off the device function, etc.</div>
      <div class="text_msg">when guardians and children withdraw their authorization, we can no longer provide the services corresponding to the withdrawal of authorization, nor do we process the corresponding information. however, the decision to withdraw the authorization will not affect the processing of information previously carried out on the basis of the authorization of the guardian and the child.</div>
      
      <h3 class="title_big" id='minV'>5. third-party services</h3>
      <div class="text_msg">The complex-design Services may provide children with access to or links to social media or other services (including websites or other forms of services) provided by third parties. Please review the "Third-Party Services" section of the complex-design Privacy Policy for more information on the types of third-party services we access or link to.</div>
      <div class="text_msg">such third-party social media or other services are operated by the relevant third party. children's use of such third party social media services or other services (including any information provided by the child to such third parties) is subject to the third party's own terms of service and information protection statement (not this policy), and guardians and children are required to read their terms carefully. this policy applies only to the personal information we collect and does not apply to any services provided by third parties or to the rules of use of information by third parties. if guardians and children find that these third-party social media or other services are at risk, they recommend that they terminate the relevant operations to protect the legitimate rights and interests of children and contact us in a timely manner.</div>
      
      <h3 class="title_big" id='minVi'>6. revision of this policy</h3>
      <div class="text_msg">In order to provide better service, we may modify the terms of this policy in accordance with the update of complex-design service and the relevant requirements of laws and regulations, and such modifications form part of this policy. If such updates result in a substantial reduction or material change in the rights of guardians and children under this Policy, we will notify them through website announcements, push notifications, pop-up prompts or other means before this Policy takes effect, and if the guardian does not agree to such changes, he may choose to require the child to stop using the complex-design Service; if the child continues to use the complex-design Service, it means that the guardian has fully read, understood and agreed to be bound by the revised Policy.</div>
      <div class="text_msg">Any changes we make will put user satisfaction first. Guardians and children are encouraged to review our Children's Personal Information Protection Rules and Guardian Notice each time they use the complex-design Services.</div>
      
      <h3 class="title_big" id='minVii'>7. how to contact us</h3>
      <div class="text_msg">We have a dedicated department for personal information protection and have designated a special person in charge of children's personal information protection, and will protect children's personal information in strict accordance with this policy. If guardians and children have complaints and reports about network information security, or have any questions, comments or suggestions about this policy, complex-design' children's personal information protection rules, and measures, please feel free to contact us and send an email to Support@complex-design.com.</div>
      <div class="text_msg">in general, we will respond within fifteen working days of receiving a question, comment or suggestion and verifying the identity of the guardian and/or child. if the guardian or child is not satisfied with our response, they may also file a complaint with the consumer protection department or file a lawsuit with a court of competent jurisdiction.</div>
      



  </div>
</template>

<script>

export default {
  data(){
      return{

      }
  },
  methods:{
     goTop(e){
      document.querySelector(e).scrollIntoView(true);
    }
  },
  created(){
   
  }

}
</script>

<style scoped>

</style>